import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getTutorialThunk = createAsyncThunk(
    'tutorial/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/tutorials/all?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addTutorialThunk = createAsyncThunk(
    "tutorial/create",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            delete body.token
            const data = await axios.post(`${api_url}/tutorials/create`, body, { 'headers': { 'Authorization': `Bearer ${token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
            // throw error
        }
    }
)

export const deleteTutorialThunk = createAsyncThunk(
    "tutorial/delete",
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.delete(`${api_url}/tutorials/delete/${body._id}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const editTutorialThunk = createAsyncThunk(
    "tutorial/update",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            let tutorialId = body.id;
            delete body.token
            const data = await axios.patch(`${api_url}/tutorials/update/${tutorialId}`, body, { 'headers': { 'Authorization': `Bearer ${token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
            // throw error
        }
    }
)



