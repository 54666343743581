import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { getUserDetailsThunk, getUsersThunk, loginThunk } from "./Thunks/userThunk";


export interface UserState {
  isLoggedIn: Boolean;
  allUser: [];
  user: {};
  goals: Object;
  totalUsers: any;
  users: any;
  page: any;
  limit: any;
  loadingStatus: any;
  error: any;

}

const initialState: UserState = {
  isLoggedIn: false,
  allUser: [],
  user: {},
  goals: {
    totalValueTarget: 1000000,
    cryptoPercentTarget: 20,
    stockPercentTarget: 70,
    cashPercentTarget: 10,
  },
  totalUsers: 0,
  users: [],
  page: 1,
  limit: 10,
  loadingStatus: "loading",
  error: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoggedIn: (state) => {
      state.isLoggedIn = !state.isLoggedIn;
    },
    getAllUsers: (state, action) => {
      state.allUser = action.payload;
    },
    setSingleUser: (state, action) => {
      state.user = action.payload;
    },
    setUserGoals: (state, action) => {
      state.goals = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginThunk.pending, (state, action: any) => {
        state.loadingStatus = "loading";
        state.error = false;
        state.isLoggedIn = false;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.loadingStatus = "";
        state.error = false;
        state.isLoggedIn = true;
        // state.user = action.payload.data.body.data;
      })
      .addCase(loginThunk.rejected, (state, action: any) => {
        state.loadingStatus = "";
        state.error = true;
        state.isLoggedIn = false;
        state.user = {}
      })

      // Get users
      .addCase(getUsersThunk.pending, (state, action: any) => {
        state.loadingStatus = "loading";
        state.error = false;
        state.users = [];
        state.totalUsers = 1;
      })
      .addCase(getUsersThunk.fulfilled, (state, action) => {
        state.loadingStatus = "";
        state.error = false;
        state.users = action.payload.data.body.data;
        state.totalUsers = action.payload.data.body.count
      })
      .addCase(getUsersThunk.rejected, (state, action: any) => {
        state.loadingStatus = "";
        state.error = true;
        state.users = [];
        state.totalUsers = 1;
      })

      // Get User Detail
      .addCase(getUserDetailsThunk.pending, (state, action: any) => {
        state.loadingStatus = "loading";
        state.error = false;
      })
      .addCase(getUserDetailsThunk.fulfilled, (state, action) => {

        state.loadingStatus = "";
        state.error = false;
      })
      .addCase(getUserDetailsThunk.rejected, (state, action: any) => {
        state.loadingStatus = "";
        state.error = true;
      })

  }
});

// Action creators are generated for each case reducer function
export const { setIsLoggedIn, getAllUsers, setSingleUser, setUserGoals } =
  userSlice.actions;

export default userSlice.reducer;
