export const faqSelector = (state: any) => {
    const {
        totalFaqs,
        faqs,
        faq,
        page,
        limit,
    } = state.faq
    return {
        totalFaqs,
        faqs,
        faq,
        page,
        limit,
    }
}