import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getTutorialThunk } from "./Thunks/tutorialThunk";


export interface tutorialState {
    totalTutorials: any,
    tutorials: any;
    tutorial: Object;
    page: any;
    limit: any;
    loadingStatus: any;
}

const initialState: tutorialState = {
    totalTutorials: 0,
    tutorials: [],
    tutorial: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
};


export const tutorialSlice = createSlice({
    name: "tutorial",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get categories
            .addCase(getTutorialThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                // state.categories = [];
            })
            .addCase(getTutorialThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.tutorials = action.payload.data.body.data;
                state.totalTutorials = action.payload.data.body.count

            })
            .addCase(getTutorialThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
            })
    }
});

export const {
    changeLimit,
    changePage,
} = tutorialSlice.actions;

export default tutorialSlice.reducer;
