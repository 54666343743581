export const tutorialSelector = (state: any) => {
    const {
        totalTutorials,
        tutorials,
        tutorial,
        page,
        limit,
    } = state.tutorial
    return {
        totalTutorials,
        tutorials,
        tutorial,
        page,
        limit,
    }
}