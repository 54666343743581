import React from 'react'
import Cards from './Cards'
import { useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Container } from '@mantine/core';
import { userSelector } from '../../Redux/Selectors/userSelector';
import { useDispatch, useSelector } from 'react-redux';
import { imageSelector } from '../../Redux/Selectors/imageSelector';
import { categorySelector } from '../../Redux/Selectors/categorySelector';
import { useNavigate } from 'react-router';
import { setIsLoggedIn } from '../../Redux/userSlice';
import { toast } from 'react-toastify';
// import {} from 'rsuite/'
import DateRangePicker from 'rsuite/DateRangePicker';
import "../../style.css"
import ReactPaginate from 'react-paginate';
import { CSVLink } from "react-csv";
import { getCategoriesThunk } from '../../Redux/Thunks/categoryThunk';
import { getUsersThunk } from '../../Redux/Thunks/userThunk';
import { getImagesThunk } from '../../Redux/Thunks/imageThunk';
import { propertiesSelector } from '../../Redux/Selectors/propertiesSelector';
import { getPropertyStatsThunk } from '../../Redux/Thunks/propertiesThunk';
import { getInvestmentStatsThunk, getTransactionStatsThunk } from '../../Redux/Thunks/investmentThunk';
import { Chart } from "react-google-charts";
// import { any } from 'prop-types';

export default function Main() {

  const imagefolder = "images"
  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT

  const { users, totalUsers } = useSelector(userSelector)
  const { images, totalImages } = useSelector(imageSelector)
  const { properties, totalProperties, totalStartingProperties, totalFullyFundedProperties, totalInprogressProperties }: any = useSelector(propertiesSelector)
  const { categories, totalCategories }: any = useSelector(categorySelector)

  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))
  const [datePikcer, setDatePicker] = useState<any>({
    fromDate: "",
    toDate: ""
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [investmentData, setInvestmentData] = useState([["Year", "Sell", "Buy"]]);
  const [investmentTab, setInvestmentTab] = useState("yearly");

  const [transactionData, setTransactionData] = useState([["Year", "Sell", "Buy"]]);
  const [transactionTab, setTransactionTab] = useState("yearly");

  const [data, setData] = useState([
    {
      label: "Users",
      value: totalUsers,
      url: '/users'
    },
    {
      label: "Properties",
      value: totalProperties,
      url: '/properties'
    },
    {
      label: "Categories",
      value: totalCategories,
      url: '/categories'
    },
    {
      label: "Fully-funded",
      value: totalFullyFundedProperties,
      url: '/properties'
    },
    {
      label: "In-progress funding",
      value: totalInprogressProperties,
      url: '/properties'
    },
    {
      label: "Funding starting soon",
      value: totalStartingProperties,
      url: '/properties'
    },
  ])

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }
  useEffect(() => {

    getData({
      page: selectedPage,
      limit: limit
    })
  }, [])
  async function getData(data: any) {
    let token = checkLoginToken()

    await dispatch(getUsersThunk({
      page: data.page,
      limit: data.limit,
      token: token
    }))

    await dispatch(getPropertyStatsThunk({
      token: token
    }))

    getInvestmentChart();
    getTransactionChart();
  }

  const options = {
    chart: {
      title: "Investment Performance",
    },
  };

  const transactionOptions = {
    chart: {
      title: "Transaction Performance",
    },
  };

  const getInvestmentChart = async (type = investmentTab) => {
    const resp: any = await dispatch(getInvestmentStatsThunk({
      token: localStorage.getItem("currentToken"),
      type: type
    }))

    let dataArr: any = [];
    if (investmentTab === "daily") {
      dataArr.push(["Date", "Buy", "Sell"]);
    } else if (investmentTab === "monthly") {
      dataArr.push(["Month", "Buy", "Sell"]);
    } else {
      dataArr.push(["Year", "Buy", "Sell"])
    }

    if (resp?.payload?.status === 200) {
      const monthlyNetAmount = resp?.payload?.data?.body?.statsData;
      monthlyNetAmount.length > 0 && monthlyNetAmount.map((item: any) => {
        dataArr.push([item?.period, item?.depositAmount, item?.withdrawAmount]);
      })
      setInvestmentData(dataArr);
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setInvestmentTab(newValue);
    getInvestmentChart(newValue);
  };

  const getTransactionChart = async (type = transactionTab) => {
    const resp: any = await dispatch(getTransactionStatsThunk({
      token: localStorage.getItem("currentToken"),
      type: type
    }))

    let dataArr: any = [];
    if (investmentTab === "daily") {
      dataArr.push(["Date", "Deposit", "Withdraw"]);
    } else if (investmentTab === "monthly") {
      dataArr.push(["Month", "Deposit", "Withdraw"]);
    } else {
      dataArr.push(["Year", "Deposit", "Withdraw"])
    }

    if (resp?.payload?.status === 200) {
      const monthlyNetAmount = resp?.payload?.data?.body?.statsData;
      monthlyNetAmount.length > 0 && monthlyNetAmount.map((item: any) => {
        dataArr.push([item?.period, item?.depositAmount, item?.withdrawAmount]);
      })
      setTransactionData(dataArr);
    }
  }

  const handleTransactionChange = (event: React.SyntheticEvent, newValue: string) => {
    setTransactionTab(newValue);
    getTransactionChart(newValue);
  };

  return (
    <>
      {/* main dashbord section */}
      <div className="main_layout dashboard_main_layout">
        <h2>Dashboard</h2>
        <Grid container spacing={2} sx={{ marginBottom: '25px' }}>
          {
            data.map((item) => {
              return (
                <Grid item md={3} onClick={() => navigate(item?.url)}>
                  <div className='dashboard_box' key={item?.label}>
                    <Cards items={item} />
                  </div>
                </Grid>
              )
            })
          }
        </Grid>

        <Grid container spacing={2} sx={{ marginBottom: '25px' }}>
          <Grid item md={6}>
            <Box sx={{ boxShadow: 2, padding: '15px', borderRadius: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '25px' }} >
                <Tabs value={investmentTab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="yearly" value="yearly" />
                  <Tab label="monthly" value="monthly" />
                  <Tab label="daily" value="daily" />
                </Tabs>
              </Box>
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={investmentData}
                options={options}
              />
            </Box>
          </Grid>
          <Grid item md={6} sx={{ marginBottom: '25px' }}>
            <Box sx={{ boxShadow: 2, padding: '15px', borderRadius: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '25px' }} >
                <Tabs value={transactionTab} onChange={handleTransactionChange} aria-label="basic tabs example">
                  <Tab label="yearly" value="yearly" />
                  <Tab label="monthly" value="monthly" />
                  <Tab label="daily" value="daily" />
                </Tabs>
              </Box>
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={transactionData}
                options={transactionOptions}
              />
            </Box>
          </Grid>
        </Grid>



      </div>
    </>
  )
}
