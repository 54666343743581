
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { setIsLoggedIn } from "../../Redux/userSlice";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import axios from "axios";
import AlertDialog from "../Dialog/Alert";
import { Loader, Placeholder } from 'rsuite';
import { HashLoader } from "react-spinners";
import useAuth from "../../utils";
import { deletePropertyDetails, getPropertiesThunk, updatePropertyDetails } from "../../Redux/Thunks/propertiesThunk";
import { propertiesSelector } from "../../Redux/Selectors/propertiesSelector";



const imagefolder = "properties"

const Main = () => {
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch()

  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT

  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))
  const [searchTerm, setSearchTerm] = useState("");
  const { properties, totalProperties }: any = useSelector(propertiesSelector)

  const navigate = useNavigate();
  const { checkLoginToken, logoutUser } = useAuth();


  async function getData(data: any) {
    try {
      setLoading(true);
      let token = checkLoginToken();
      let response: any = await dispatch(getPropertiesThunk({
        page: data.page,
        limit: data.limit,
        token: token,
        keyword: searchTerm
      }));

      if (response.payload.response && response.payload.response.status) {
        if (response.payload.response.status === 401) {
          logoutUser();
        }
        toast.error(response.payload.response.data.message);
      } else {
        pageCounterr();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData({
      page: selectedPage,
      limit: limit
    })
  }, [])

  const handleEdit = async (item: any) => {
    navigate('/property/addedit', { state: item })
  }

  const handleView = async (item: any) => {
    navigate('/property/view', { state: item })
  }


  const [deleteDialog, setDeleteDialog] = useState(false)
  const [elementId, setElementId] = useState("")

  const handleDeleteModal = async (e: any, _id: any) => {
    setDeleteDialog(true)
    setElementId(_id)
  }

  const handleDeleteProperty = async (id: any) => {
    try {

      let token = checkLoginToken()

      let payload = {
        _id: id,
        token: token
      }

      let response: any = await dispatch(deletePropertyDetails(payload))


      if (response.payload?.status === 200) {
        toast.success(response.payload?.data.message)
        await dispatch(getPropertiesThunk(
          {
            page: selectedPage,
            limit: limit,
            token: token,
            keyword: searchTerm
          }
        ))
        setDeleteDialog(false)
        setElementId("")
      } else {
        toast.error("Something went wrong.")
      }



    } catch (err) {
      console.log(err);
    }
  };


  const handleUpdatePropertyDetails = async (id: any, field: string, value: any) => {
    try {
      let token = checkLoginToken();
      let response: any = await dispatch(updatePropertyDetails({
        _id: id,
        [field]: value,
        token: token
      }));
      console.log("response", response);


      if (response.payload.status === 200) {
        await dispatch(getPropertiesThunk(
          {
            page: selectedPage,
            limit: limit,
            token: token,
            keyword: searchTerm
          }
        ))
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const rows = properties && properties.length > 0 && properties.map((row: any, index: number) => {

    let rowImage = row.property_images && row.property_images.length > 0 && row.property_images.find((ele: any) => ele.featured)?.image || row.property_images[0].image

    return (
      <tr key={row._id}>
        <td>
          {index + 1}.
        </td>
        <td>{rowImage ?
          <img src={(process.env.REACT_APP_BACKEND_BASE_URL + `/uploads/${imagefolder}/thumbnail/` + rowImage)} alt={rowImage} height="80px" />
          : "Not found"}
        </td>
        <td>{row.name ? row.name : "-"}</td>
        <td>{row.location ? row.location : "-"}</td>
        <td>{row.address ? row.address : "-"}</td>

        <td><label className="switch">
          <input type="checkbox" checked={row.status} onChange={() => {
            handleUpdatePropertyDetails(row._id, "status", !row.status)
          }} />
          <span className="slider round"></span>
        </label>
        </td>

        <td>
          <select value={row.funding_status} onChange={(e) => {
            handleUpdatePropertyDetails(row._id, "funding_status", e.target.value)
          }}>
            <option value={"fully_funded"}>
              Fully Funded
            </option>

            <option value={"funding_starting_soon"}>
              Funding Starting Soon
            </option>

            <option value={"funding_in_progress"}>
              Funding in Progress
            </option>

          </select>

        </td>
        <td>{row?.createdAt?.split("T")[0]}</td>
        <td>{row?.updatedAt?.split("T")[0]}</td>
        <td style={{ cursor: "pointer" }}>
          <ViewSvg height={"20px"} style={{ cursor: "pointer" }} onClick={() => handleView(row)} />
          <EditSvg height={"15px"} style={{ cursor: "pointer" }} onClick={() => handleEdit(row)} />
          <DeleteSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
            handleDeleteModal(e, row._id)
          } /> </td>
      </tr>
    )
  });

  const [pageCount, setPageCount] = useState(1);

  const pageCounterr = () => {
    const pageCounter: any = Math.ceil(parseInt(totalProperties) / parseInt(limit))
    setPageCount(pageCounter)
  }

  const handlePageClick = async (data: any) => {
    setSelectedPage(data.selected + 1);
    await getData({ page: data.selected + 1, limit: limit, keyword: searchTerm })
  }

  const handleSearchOption = async (keyword: any) => {
    setSearchTerm(keyword);
    let token = checkLoginToken();

    let response: any = await dispatch(getPropertiesThunk(
      {
        page: selectedPage,
        limit: limit,
        token: token,
        keyword: keyword,
      }
    ))
  };

  return (
    <>
      {
        // !isLoading ? (
        <div className="main_layout">

          <div className="header-h2">
            <h2>Properties List</h2>
            <div className="search-center">
              <input type="text" placeholder="Search" className="form-control" onChange={(e) => handleSearchOption(e.target.value)} />
              <button className="btn add_btn" color="warning" onClick={() => {
                navigate("/property/addedit")
              }}>Add Property</button>
            </div>
          </div>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Image</th>
                  <th>Property Name</th>
                  <th>Location</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Funding Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Action</th>

                </tr>
              </thead>

              <tbody>
                {rows && rows.length > 0 && rows}

              </tbody>
            </table>

            {
              properties && properties.length > 0 && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  //    breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"pagination_prev"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"pagination_next"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={selectedPage - 1}
                />
              )
            }

            <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
              handleDeleteProperty(elementId)
            }} />
          </div>

        </div>
        // ) : (
        // <div className="spinner" >

        //   <HashLoader color="#1864ab" size={75} loading={true} />
        // </div>
        // )
      }



    </>

  );
};

export default Main;
