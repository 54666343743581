
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { getInvestmentsThunk } from '../../Redux/Thunks/investmentThunk';
import { investmentSelector } from "../../Redux/Selectors/investmentSelector";
import moment from 'moment';
import AlertDialog from "../Dialog/Alert";

const List = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { investments, totalInvestments }: any = useSelector(investmentSelector)
    const token = localStorage.getItem('currentToken');
    const page: any = process.env.REACT_APP_PAGE
    const limit: any = process.env.REACT_APP_LIMIT
    const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))
    const [searchTerm, setSearchTerm] = useState("");
    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(totalInvestments / limit);
        return pageCounter;
    }, [totalInvestments, limit]);

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [elementId, setElementId] = useState("")

    useEffect(() => {
        getlist();
    }, [])

    const getlist = async () => {
        const resp: any = await dispatch(getInvestmentsThunk({ page: 1, limit: limit, token: token, keyword: searchTerm }));
    }

    const handlePageClick = async (data: any) => {
        setSelectedPage(data.selected + 1);
        await dispatch(
            getInvestmentsThunk({
                limit: limit,
                page: data.selected + 1,
                token: token,
                keyword: searchTerm
            })
        );
    };

    const handleSearchOption = async (keyword: any) => {
        setSearchTerm(keyword);
        let response: any = await dispatch(getInvestmentsThunk(
            {
                page: selectedPage,
                limit: limit,
                token: token,
                keyword: keyword,
            }
        ))
    };

    return (
        <>
            <div className="main_layout">
                <div className="header-h2">
                    <h2>Investment List</h2>
                    <div className="search-center">
                        <input type="text" placeholder="Search" className="form-control" onChange={(e) => handleSearchOption(e.target.value)} />
                    </div>
                </div>

                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Property</th>
                                <th>Property Address</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                totalInvestments > 0 ?
                                    investments.map((item: any) => {
                                        return (
                                            <tr key={item?._id}>
                                                <td>{item?.user?.full_name}</td>
                                                <td>{item?.property?.name}</td>
                                                <td>{item?.property?.address}, {item?.property?.location}, {item?.property?.city}, {item?.property?.country}, {item?.property?.postal_code}</td>
                                                <td>{item?.amount}</td>
                                                <td>{item?.type}</td>
                                                <td>{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        )
                                    })
                                    : <tr><td colSpan={3} className='text-center'>No record found</td></tr>
                            }
                        </tbody>
                    </table>
                    {
                        totalInvestments > 0 && (
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                //    breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"pagination_prev"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"pagination_next"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}

                            />
                        )
                    }
                </div>
            </div >
        </>
    );
};

export default List;
