
import { ReactComponent as listIconSvg } from "../images/list.svg"
import { ReactComponent as UserSvg } from "../images/users-svgrepo-com.svg"
import { ReactComponent as ImagesSvg } from "../images/images-svgrepo-com.svg"
import { ReactComponent as BuildingsIconSvg } from "../images/buildings-building-construction-estate-svgrepo-com.svg"
import { ReactComponent as PaymentSvg } from "../images/payment.svg"
import { ReactComponent as OrderSvg } from "../images/order.svg"
import { ReactComponent as FaqSvg } from "../images/faq.svg"
import { ReactComponent as TutorialSvg } from "../images/tutorial.svg"
import { ReactComponent as CategorySvg } from "../images/category.svg"
import { ReactComponent as MessageSvg } from "../images/messages.svg"

export function NavigationLinkData() {

  const navLinkData = [
    { link: `/dashboard`, label: "Dashboard", icon: listIconSvg },
    { link: `/users`, label: "Users", icon: UserSvg },
    { link: `/properties`, label: "Properties", icon: BuildingsIconSvg },
    { link: `/categories`, label: "Categories", icon: CategorySvg },
    { link: `/subscribers`, label: "Subscribers", icon: UserSvg },
    { link: `/investments`, label: "Investments", icon: OrderSvg },
    { link: `/payments`, label: "Payments", icon: PaymentSvg },
    { link: `/payouts`, label: "Payouts", icon: PaymentSvg },
    { link: `/faqs`, label: "FAQs", icon: FaqSvg },
    { link: `/tutorials`, label: "Tutorials", icon: TutorialSvg },
    { link: `/blogs`, label: "Blogs", icon: TutorialSvg },
    { link: `/messages`, label: "Messages", icon: MessageSvg },
  ];

  return {
    navLinkData,
  };
}
