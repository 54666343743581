import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getFaqsThunk } from "./Thunks/faqThunk";


export interface FaqState {
    totalFaqs: any,
    faqs: any;
    faq: Object;
    page: any;
    limit: any;
    loadingStatus: any;
}

const initialState: FaqState = {
    totalFaqs: 0,
    faqs: [],
    faq: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
};


export const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get categories
            .addCase(getFaqsThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                // state.categories = [];
            })
            .addCase(getFaqsThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.faqs = action.payload.data.body.data;
                state.totalFaqs = action.payload.data.body.count

            })
            .addCase(getFaqsThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
            })
    }
});

export const {
    changeLimit,
    changePage,
} = faqSlice.actions;

export default faqSlice.reducer;
