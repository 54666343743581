import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getInvestmentsThunk = createAsyncThunk(
    'investment/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/investments/list/?page=${body.page}&limit=${body.limit}&keyword=${body.keyword}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getInvestmentStatsThunk = createAsyncThunk(
    'investment/stats',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/investments/stats/?type=${body.type}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getTransactionStatsThunk = createAsyncThunk(
    'transaction/stats',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/transactions/stats/?type=${body.type}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)




