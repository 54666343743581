import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getMessageThunk, deleteMessageThunk } from "./Thunks/messageThunk";

export interface MessageState {
    totalMessage: any,
    messages: any;
    page: any;
    limit: any;
    loadingStatus: any;
}

const initialState: MessageState = {
    totalMessage: 0,
    messages: [],
    page: 1,
    limit: 10,
    loadingStatus: "loading",
};

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            // Get blogs
            .addCase(getMessageThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.messages = action.payload.data.body.data;
                state.totalMessage = action.payload.data.body.count
            })

            .addCase(deleteMessageThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
            })

    }
});

export default messageSlice.reducer;
