export const investmentSelector = (state: any) => {
    const {
        totalInvestments,
        investments,
        page,
        limit,
        loadingStatus,
        error,
        isLoggedIn,
        investment
    } = state.investment
    return {
        totalInvestments,
        investments,
        page,
        limit,
        loadingStatus,
        error,
        isLoggedIn,
        investment
    }
}