export const blogSelector = (state: any) => {
    const {
        totalBlogs,
        blogs,
        blog,
        page,
        limit,
        loadingStatus,
    } = state.blog
    return {
        totalBlogs,
        blogs,
        blog,
        page,
        limit,
        loadingStatus,
    }
}