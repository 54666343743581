export const paymentSelector = (state: any) => {
    const {
        totalpayments,
        payments,
        page,
        limit,
        loadingStatus,
        error,
        isLoggedIn,
        payment,

        totalpayouts,
        payouts,
        payoutpage,
        payoutlimit,
    } = state.payment
    return {
        totalpayments,
        payments,
        page,
        limit,
        loadingStatus,
        error,
        isLoggedIn,
        payment,

        totalpayouts,
        payouts,
        payoutpage,
        payoutlimit,
    }
}