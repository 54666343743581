
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { getFaqsThunk, deleteFaqThunk } from '../../Redux/Thunks/faqThunk';
import { faqSelector } from "../../Redux/Selectors/faqSelector";
import moment from 'moment';
import AlertDialog from "../Dialog/Alert";
import {
    changeLimit,
    changePage,
} from "../../Redux/faqSlice";

const List = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { faqs, totalFaqs, page, limit }: any = useSelector(faqSelector)
    const token = localStorage.getItem('currentToken');

    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(totalFaqs / limit);
        return pageCounter;
    }, [totalFaqs, limit]);

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [elementId, setElementId] = useState("")

    useEffect(() => {
        getFaqs();
    }, [])

    const getFaqs = async () => {
        await dispatch(getFaqsThunk({ page: 1, limit: limit, token: token }));
    }

    const handlePageClick = async (data: any) => {
        console.log(data)
        await dispatch(
            getFaqsThunk({
                limit: limit,
                page: data.selected + 1,
                token: token,
            })
        );
    };

    const handleDeleteModal = async (e: any, _id: any) => {
        setDeleteDialog(true)
        setElementId(_id)
    }

    const handleDeleteFaq = async (id: any) => {
        try {
            let payload = {
                _id: id,
                token: token
            }
            let response: any = await dispatch(deleteFaqThunk(payload))

            if (response.payload?.status === 200) {
                toast.success(response.payload?.data.message)
                await dispatch(getFaqsThunk(
                    { page: 1, limit: limit, token: token }
                ))
                setDeleteDialog(false)
                setElementId("")
            } else {
                toast.error("Something went wrong.")
            }
        } catch (err) {
            console.log(err);
        }
    };

    console.log(limit)

    return (
        <>
            <div className="main_layout">

                <div className="header-h2">
                    <h2>FAQ List</h2>
                    <div className="search-center">
                        <button className="btn add_btn" color="warning" onClick={() => {
                            navigate("/faqs/create")
                        }}>Add FAQ</button>
                    </div>
                </div>

                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>

                                <th>Question</th>
                                <th>Answer</th>
                                <th>Status</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                <th>Action</th>

                            </tr>
                        </thead>

                        <tbody>
                            {
                                totalFaqs > 0 ?
                                    faqs.map((item: any) => {
                                        return (
                                            <tr key={item?._id}>

                                                <td>{item?.question}</td>
                                                <td>{item?.answer}</td>
                                                <td>{item?.status ? 'Active' : 'Inactive'}</td>
                                                <td>{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>{moment(item?.updatedAt).format('YYYY-MM-DD')}</td>
                                                <td style={{ cursor: "pointer" }}>


                                                    <EditSvg height={"15px"} style={{ cursor: "pointer" }} onClick={() => navigate("/faqs/edit", { state: { faq: item } })} />
                                                    <DeleteSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
                                                        handleDeleteModal(e, item._id)
                                                    } /> </td>
                                            </tr>

                                        )
                                    })
                                    : <tr><td colSpan={5} className="text-center">No record found</td></tr>
                            }

                        </tbody>
                    </table>
                    {
                        totalFaqs > 0 && (
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                //    breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"pagination_prev"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"pagination_next"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}

                            />
                        )
                    }

                    <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
                        handleDeleteFaq(elementId)
                    }} />

                </div>

            </div >
        </>

    );
};

export default List;
