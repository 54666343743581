import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getPaymentsThunk = createAsyncThunk(
    'payment/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/payments/list?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getPayoutsThunk = createAsyncThunk(
    'payouts/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/payouts/list?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updatePayoutThunk = createAsyncThunk(
    'payout/update',
    async (body: any, { rejectWithValue }) => {
        try {
            let payload = { ...body }
            delete payload.token
            const data = await axios.patch(`${api_url}/payouts/update/${body.id}`, { ...payload }, { 'headers': { 'Authorization': `Bearer ${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)




