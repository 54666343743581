import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getFaqsThunk = createAsyncThunk(
    'faq/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/faqs/all?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addFaqThunk = createAsyncThunk(
    "faq/create",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            delete body.token
            const data = await axios.post(`${api_url}/faqs/create`, body, { 'headers': { 'Authorization': `Bearer ${token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
            // throw error
        }
    }
)

export const deleteFaqThunk = createAsyncThunk(
    "faq/delete",
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.delete(`${api_url}/faqs/delete/${body._id}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const editFaqThunk = createAsyncThunk(
    "faq/update",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            let faqId = body.id;
            delete body.token
            const data = await axios.patch(`${api_url}/faqs/update/${faqId}`, body, { 'headers': { 'Authorization': `Bearer ${token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
            // throw error
        }
    }
)



