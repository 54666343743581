import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;


export const getPropertiesThunk = createAsyncThunk(
    'properties/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/properties?page=${body.page}&limit=${body.limit}&keyword=${body.keyword}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const getPropertyDetailsThunk = createAsyncThunk(
    'properties/detail',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/properties/property_details/${body.property_id}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const updatePropertyDetails = createAsyncThunk(
    'property/updates',
    async (body: any, { rejectWithValue }) => {
        try {
            let payload = { ...body }
            delete payload.token
            const data = await axios.post(`${api_url}/properties/updateProperty`, { ...payload }, { 'headers': { 'Authorization': `Bearer ${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const deletePropertyDetails = createAsyncThunk(
    'property/delete',
    async (payload: any, { rejectWithValue }) => {
        try {

            const data = await axios.delete(`${api_url}/properties/delete/${payload._id}`, { 'headers': { 'Authorization': `Bearer ${payload.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const getPropertyStatsThunk = createAsyncThunk(
    'properties/stats',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/properties/stats`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)




