import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getMessageThunk = createAsyncThunk(
    'message/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/messages/all?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteMessageThunk = createAsyncThunk(
    "message/delete",
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.delete(`${api_url}/messages/delete/${body._id}`, { 'headers': { 'Authorization': `Bearer ${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)




