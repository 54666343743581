
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addBlogThunk } from '../../Redux/Thunks/blogThunk';
import axios from 'axios';

const CreateBlog = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        title: Yup.string().trim().required("Title is required"),
        description: Yup.string().trim().required("Description is required"),
        status: Yup.string().required("Status is required"),
        image: Yup.string().required("Image is required"),
    });

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            image: "",
            status: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const formData = new FormData();
                formData.append("title", values.title);
                formData.append("description", values.description);
                formData.append("status", values.status);
                formData.append("image", values.image);
                let token = localStorage.getItem('currentToken');
                let resp: any = await axios.post(
                    `${process.env.REACT_APP_BACKEND_API_BASE_URL}/blogs/create`,
                    formData,
                    {
                        headers: {
                            "Authorization": 'Bearer ' + token,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                //const resp: any = await dispatch(addTutorialThunk({ ...values, token: token }));
                if (resp?.status === 200) {
                    toast.success('Blog details saved successfully')
                    navigate("/blogs");
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } catch (error) {
                console.log("error", error);
                toast.error("Something went wrong.");
            }
        },
    });

    const handleImage = (e: any) => {
        if (e.target.files.length > 0) {
            formik.setFieldValue("image", e.target.files[0]);
        } else {
            formik.setFieldValue("image", "");
        }
    };

    return (
        <>
            <div className="main_layout">
                <h2>Add Blog
                </h2>
                <div className="add_edit_box">
                    <form className='add-category' onSubmit={formik.handleSubmit}>
                        <div className="flex-fg">

                            <div className='form-group'>
                                <label>Title</label>
                                <input
                                    name="title"
                                    type="text"
                                    placeholder="Enter Blog Title"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                                <div className="invalid-feedback">
                                    {formik.errors.title && formik.touched.title
                                        ? formik.errors.title
                                        : null}
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    placeholder="Enter Description"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                ></textarea>
                                <div className="invalid-feedback">
                                    {formik.errors.description && formik.touched.description
                                        ? formik.errors.description
                                        : null}
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Image</label>
                                <input
                                    name="image"
                                    type="file"
                                    className="form-control"
                                    accept="image/*"
                                    onChange={(e) => handleImage(e)}
                                //onChange={formik.handleChange}
                                />
                                <div className="invalid-feedback">
                                    {formik.errors.image && formik.touched.image
                                        ? formik.errors.image
                                        : null}
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Status</label>
                                <select
                                    name="status"
                                    placeholder="Select Funding Status"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.status}
                                >
                                    <option value="">Select</option>
                                    <option value="true">
                                        Active
                                    </option>

                                    <option value="false">
                                        Inactive
                                    </option>
                                </select>
                                <div className="invalid-feedback">
                                    {formik.errors.status && formik.touched.status
                                        ? formik.errors.status
                                        : null}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
                    </form>
                </div>

            </div>
        </>

    );
};

export default CreateBlog;
