export const messageSelector = (state: any) => {
    const {
        totalMessage,
        messages,
        page,
        limit,
        loadingStatus,
    } = state.message
    return {
        totalMessage,
        messages,
        page,
        limit,
        loadingStatus,
    }
}