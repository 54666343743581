import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSubscribersThunk } from "./Thunks/subscriberThunk";


export interface SubscriberState {
    totalSubscribers: any,
    subscribers: any;
    subscriber: Object;
    page: any;
    limit: any;
    loadingStatus: any;
}

const initialState: SubscriberState = {
    totalSubscribers: 0,
    subscribers: [],
    subscriber: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
};


export const subscriberSlice = createSlice({
    name: "subscriber",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.subscribers = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get categories
            .addCase(getSubscribersThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                // state.categories = [];
            })
            .addCase(getSubscribersThunk.fulfilled, (state, action) => {
                console.log("GET fulffilled")
                state.loadingStatus = "";
                state.subscribers = action.payload.data.body.data;
                state.totalSubscribers = action.payload.data.body.count

            })
            .addCase(getSubscribersThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
            })
    }
});

// Action creators are generated for each case reducer function
export const { getAll } =
    subscriberSlice.actions;

export default subscriberSlice.reducer;
