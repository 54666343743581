
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addCategoryThunk } from '../../Redux/Thunks/categoryThunk';

const CreateCategory = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        status: Yup.string().required("Status is required"),
    });

    const formik = useFormik({
        initialValues: {
            title: "",
            status: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                let token = localStorage.getItem('currentToken');
                const resp: any = await dispatch(addCategoryThunk({ ...values, token: token }));
                if (resp?.payload?.status === 200) {
                    toast.success('Category details saved successfully')
                    navigate("/categories");
                } else {
                    toast.error(resp?.payload?.response?.data?.message);
                }
            } catch (error) {
                console.log("error", error);
                toast.error("Something went wrong.");
            }
        },
    });

    return (
        <>
            <div className="main_layout">
                <h2>Add Category
                </h2>
                <div className="add_edit_box">
                    <form className='add-category' onSubmit={formik.handleSubmit}>
                        <div className="flex-fg">

                            <div className='form-group'>
                                <label>Title</label>
                                <input
                                    name="title"
                                    type="text"
                                    placeholder="Enter Category Title"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                                <div className="invalid-feedback">
                                    {formik.errors.title && formik.touched.title
                                        ? formik.errors.title
                                        : null}
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Status</label>
                                <select
                                    name="status"
                                    placeholder="Select Funding Status"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.status}
                                >
                                    <option value="">Select</option>
                                    <option value="true">
                                        Active
                                    </option>

                                    <option value="false">
                                        Inactive
                                    </option>
                                </select>
                                <div className="invalid-feedback">
                                    {formik.errors.status && formik.touched.status
                                        ? formik.errors.status
                                        : null}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
                    </form>
                </div>

            </div>
        </>

    );
};

export default CreateCategory;
