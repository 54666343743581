import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getBlogsThunk, addBlogThunk, deleteBlogThunk } from "./Thunks/blogThunk";

export interface BlogState {
    totalBlogs: any,
    blogs: any;
    blog: Object;
    page: any;
    limit: any;
    loadingStatus: any;
}

const initialState: BlogState = {
    totalBlogs: 0,
    blogs: [],
    blog: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
};

export const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            // Get blogs
            .addCase(getBlogsThunk.fulfilled, (state, action) => {
                console.log("GET fulffilled")
                state.loadingStatus = "";
                state.blogs = action.payload.data.body.data;
                state.totalBlogs = action.payload.data.body.count
            })

            .addCase(addBlogThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
            })

            .addCase(deleteBlogThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
            })

    }
});

export default blogSlice.reducer;
