import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { deletePropertyDetails, getPropertiesThunk, getPropertyDetailsThunk, getPropertyStatsThunk } from "./Thunks/propertiesThunk";


export interface PropertyState {
    property: any;
    properties: any;
    totalProperties: any;
    page: any;
    limit: any;
    loadingStatus: any;
    error: any;
    totalStartingProperties: any;
    totalFullyFundedProperties: any;
    totalInprogressProperties: any;
}

const initialState: PropertyState = {
    property: null,
    properties: [],
    totalProperties: 0,
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    error: false,
    totalStartingProperties: 0,
    totalFullyFundedProperties: 0,
    totalInprogressProperties: 0
};

export const propertySlice = createSlice({
    name: "property",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder

            // Get Properties
            .addCase(getPropertiesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
                state.properties = [];
                state.totalProperties = 1;
            })
            .addCase(getPropertiesThunk.fulfilled, (state, action) => {
                // console.log(" action.payload.data.body.count", action.payload.data.body.count);
                state.loadingStatus = "";
                state.error = false;
                state.properties = action.payload.data.body.data;
                state.totalProperties = action.payload.data.body.count
            })
            .addCase(getPropertiesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
                state.properties = [];
                state.totalProperties = 1;
            })

            .addCase(getPropertyStatsThunk.fulfilled, (state, action) => {
                state.totalStartingProperties = action.payload.data.body.totalStartingProperties;
                state.totalFullyFundedProperties = action.payload.data.body.totalFullyFundedProperties;
                state.totalInprogressProperties = action.payload.data.body.totalInprogressProperties;
            })


    }
});

// Action creators are generated for each case reducer function
// export const { setIsLoggedIn, getAllUsers, setSingleUser, setUserGoals } =
//     userSlice.actions;
export default propertySlice.reducer;
