
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addFaqThunk } from '../../Redux/Thunks/faqThunk';

const CreateFaq = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        question: Yup.string().required("question is required"),
        answer: Yup.string().required("answer is required"),
        status: Yup.string().required("Status is required"),
    });

    const formik = useFormik({
        initialValues: {
            question: "",
            answer: "",
            status: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                let token = localStorage.getItem('currentToken');
                const resp: any = await dispatch(addFaqThunk({ ...values, token: token }));
                if (resp?.payload?.status === 200) {
                    toast.success('FAQ details saved successfully')
                    navigate("/faqs");
                } else {
                    toast.error(resp?.payload?.response?.data?.message);
                }
            } catch (error) {
                console.log("error", error);
                toast.error("Something went wrong.");
            }
        },
    });

    return (
        <>
            <div className="main_layout">
                <h2>Add FAQ
                </h2>
                <div className="add_edit_box">
                    <form className='add-category' onSubmit={formik.handleSubmit}>
                        <div className="flex-fg">

                            <div className='form-group'>
                                <label>Question</label>
                                <textarea
                                    name="question"
                                    placeholder="Enter Question"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                >{formik.values.question}</textarea>

                                <div className="invalid-feedback">
                                    {formik.errors.question && formik.touched.question
                                        ? formik.errors.question
                                        : null}
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Answer</label>
                                <textarea
                                    name="answer"
                                    placeholder="Enter Answer"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                >{formik.values.answer}</textarea>

                                <div className="invalid-feedback">
                                    {formik.errors.answer && formik.touched.answer
                                        ? formik.errors.answer
                                        : null}
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Status</label>
                                <select
                                    name="status"
                                    placeholder="Select Funding Status"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.status}
                                >
                                    <option value="">Select</option>
                                    <option value="true">
                                        Active
                                    </option>

                                    <option value="false">
                                        Inactive
                                    </option>
                                </select>
                                <div className="invalid-feedback">
                                    {formik.errors.status && formik.touched.status
                                        ? formik.errors.status
                                        : null}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
                    </form>
                </div>

            </div>
        </>

    );
};

export default CreateFaq;
