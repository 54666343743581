import {
    Text,
} from "@mantine/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

import { SelectedImagesWithPreview } from "../SelectedImagesWithPreview";
import { Grid } from "@mui/material";
import { getUserDetailsThunk } from "../../Redux/Thunks/userThunk";
import { HashLoader } from "react-spinners";
import useAuth from "../../utils";
import ReactFlagsSelect from "react-flags-select";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const imagefolder = "users"

const AddEditMain = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [addMode, setAddMode] = useState(true)

    const [isLoading, setLoading] = useState(false);

    const { checkLoginToken, logoutUser } = useAuth();

    // const [deletedImages , setDeletedImages] = useState([])
    const [existingInvestorDocuments, setExistingInvestorDocuments] = useState([])
    const [existingGovernmentIdProofDocuments, setExistingGovernmentIdProofDocuments] = useState([])
    const [existingPassportProofDocuments, setExistingPassportProofDocuments] = useState([])
    const [existingAddressProofDocuments, setExistingAddressProofDocuments] = useState([])


    useEffect(() => {

        (async () => {
            try {
                if (location && location.state && Object.entries(location.state).length > 0) {



                    let token = checkLoginToken()
                    let response: any = await dispatch(getUserDetailsThunk(
                        {
                            user_id: location.state._id,
                            token: token
                        }
                    ))

                    console.log("response---", response)


                    if (response.payload.response && response.payload.response.status) {
                        if (response.payload.response.status == 401) {
                            setTimeout(() => {

                                setLoading(false)
                            }, 500);
                            logoutUser()
                        }
                        toast.error(response.payload.response.data.message)
                    }

                    else if (response.payload.status == 200 && response.payload.data.body) {
                        setTimeout(() => {

                            setLoading(false)
                        }, 500);

                        let userObj = response.payload.data.body;
                        let fullname = userObj?.full_name ? userObj?.full_name.split(" ") : [];

                        formik.setValues({
                            first_name: userObj?.first_name != "" ? userObj?.first_name : fullname[0],
                            last_name: userObj?.last_name != "" ? userObj?.last_name : fullname[1],

                            username: userObj.username || "",
                            password: '',
                            email: userObj.email,
                            phone_number: userObj.phone_number || '',
                            country_code: userObj.country_code || '',

                            type_of_account: userObj.type_of_account || "",
                            nationality: userObj.nationality || "",
                            dob: userObj.dob || "",
                            city_of_birth: userObj.city_of_birth || "",
                            native_country: userObj.native_country || "",
                            address: userObj.address || "",
                            postal_code: userObj.postal_code || "",
                            city: userObj.city || "",
                            country: userObj.country || "",

                            investment_objectives: userObj.investment_objectives || [],
                            investment_horizon: userObj.investment_horizon || "",
                            professional_status: userObj.professional_status || "",
                            income_currency: userObj.income_currency || "",
                            annual_income_range: userObj.annual_income_range || "",
                            estimated_total_wealth: userObj.estimated_total_wealth || "",

                            _id: userObj._id || ""
                        })

                        // setExistingInvestorDocuments(userObj.investor_proof_document)
                        // setExistingGovernmentIdProofDocuments(userObj.government_id_proof)
                        // setExistingPassportProofDocuments(userObj.passport_proof_document)
                        // setExistingAddressProofDocuments(userObj.address_proof)

                        setAddMode(false)
                    }


                }
            } catch (err) {
                console.error(err);
            }
        })();


    }, [])

    const validationSchema = Yup.object().shape({
        username: Yup.string().required("Username is required"),
        ...(addMode ? { email: Yup.string().email("Field should contain a valid e-mail").required("E-mail is required") } : {}),
        phone_number: Yup.string().required("Phone Number is required"),
        country_code: Yup.string().required("Country Code is required"),
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string().required("Last Name is required"),
        type_of_account: Yup.string().required("Account Type is required"),
        nationality: Yup.string().required("Nationality is required"),
        dob: Yup.date()
            .required('Date of Birth is required')
            .max(new Date(), 'Date of Birth cannot be in the future')
            .min(new Date('1900-01-01'), 'Date of Birth must be after 1900-01-01'),
        //dob: Yup.string().required("Date Of Birth is required"),
        city_of_birth: Yup.string().required("City Of Birth is required"),
        native_country: Yup.string().required("Native Country is required"),
        address: Yup.string().required("Address is required"),
        postal_code: Yup.string().required("Postal Code is required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),


        investment_objectives: Yup.array().of(Yup.string()).min(1, "Atleast one objective should be selected").required("Investment Objectives are required"),
        investment_horizon: Yup.string().required("Investment Horizon is required"),
        professional_status: Yup.string().required("Professional Status is required"),
        income_currency: Yup.string().required("Income Currency is required"),
        annual_income_range: Yup.string().required("Annual Income Range is required"),
        estimated_total_wealth: Yup.string().required("Estimated Total Wealth is required"),


        ...(addMode ? {
            password: Yup.string().required("Password is required").min(8, { length: "Password must be atleast 8 characters long" })
                .matches(/\d+/, { message: { number: "Password must atleast contain a number" } })
                .matches(/[a-z]+/, { message: { lowercase: "Password must atleast contain a lowercase character" } })
                .matches(/[A-Z]+/, { message: { uppercase: "Password must atleast contain a uppercase character" } })
                .matches(/[!@#$%^&*()-+]+/, {
                    message: { special: "Password must atleast contain a special character" }
                })
        } : {}),
        // ...(addMode ? {
        //     investor_proof_document: Yup.array()
        //         .min(1, 'At least one document is required')
        //         .of(
        //             Yup.mixed()
        //                 .test(
        //                     'fileSize',
        //                     'File size is too large1',
        //                     (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
        //                 )
        //                 .test(
        //                     'fileType',
        //                     'Unsupported file type',
        //                     (value) =>
        //                         value &&
        //                         (value.type.startsWith('image/') || value.type === 'application/pdf')
        //                 )
        //         ),
        // } : {}),

        // ...(addMode ? {
        //     government_id_proof: Yup.array()
        //         .min(1, 'At least one document is required')
        //         .of(
        //             Yup.mixed()
        //                 .test(
        //                     'fileSize',
        //                     'File size is too large1',
        //                     (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
        //                 )
        //                 .test(
        //                     'fileType',
        //                     'Unsupported file type',
        //                     (value) =>
        //                         value &&
        //                         (value.type.startsWith('image/') || value.type === 'application/pdf')
        //                 )
        //         ),
        // } : {}),

        // ...(addMode ? {
        //     passport_proof_document: Yup.array()
        //         .min(1, 'At least one document is required')
        //         .of(
        //             Yup.mixed()
        //                 .test(
        //                     'fileSize',
        //                     'File size is too large1',
        //                     (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
        //                 )
        //                 .test(
        //                     'fileType',
        //                     'Unsupported file type',
        //                     (value) =>
        //                         value &&
        //                         (value.type.startsWith('image/') || value.type === 'application/pdf')
        //                 )
        //         ),
        // } : {}),


        // ...(addMode ? {
        //     address_proof: Yup.array()
        //         .min(1, 'At least one document is required')
        //         .of(
        //             Yup.mixed()
        //                 .test(
        //                     'fileSize',
        //                     'File size is too large1',
        //                     (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
        //                 )
        //                 .test(
        //                     'fileType',
        //                     'Unsupported file type',
        //                     (value) =>
        //                         value &&
        //                         (value.type.startsWith('image/') || value.type === 'application/pdf')
        //                 )
        //         ),
        // } : {}),

    });

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {

            email: "",
            username: "",
            first_name: "",
            last_name: "",
            password: "",

            phone_number: "",
            country_code: "",
            // investor_proof_document: [],
            // government_id_proof: [],
            // passport_proof_document: [],
            // address_proof: [],

            type_of_account: "",
            nationality: "",
            dob: "",
            city_of_birth: "",
            native_country: "",
            address: "",
            postal_code: "",
            city: "",
            country: "",


            investment_objectives: [],
            investment_horizon: "",
            professional_status: "",
            income_currency: "",
            annual_income_range: "",
            estimated_total_wealth: "",

            _id: ""

        },
        validateOnChange: true,
        validationSchema: validationSchema,

        onSubmit: async (data, { resetForm }) => {

            await handleCreateUser(data)
            if (addMode) {
                // setFile(null)
                // setFileDataURL(null)

                // resetForm()
                // navigate("/users")
            }
        }
    });


    const handleCreateUser = async (data: any) => {
        try {
            let token = checkLoginToken()

            let payload: any = { ...data }

            if (data && data.investor_proof_document) {
                let formData = new FormData()

                formData.append("folder", imagefolder);
                data.investor_proof_document.forEach((ele: any) => {
                    formData.append("image", ele);
                })

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `Bearer ${token}` } })
                if (resp.statusText == "OK") {
                    let filenames = resp.data.body.filenames;
                    payload.investor_proof_document = filenames;
                }
            }

            if (data && data.government_id_proof) {
                let formData = new FormData()

                formData.append("folder", imagefolder);
                data.government_id_proof.forEach((ele: any) => {
                    formData.append("image", ele);
                })

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `Bearer ${token}` } })
                if (resp.statusText == "OK") {
                    let filenames = resp.data.body.filenames;
                    payload.government_id_proof = filenames;
                }
            }


            if (data && data.passport_proof_document) {
                let formData = new FormData()

                formData.append("folder", imagefolder);
                data.passport_proof_document.forEach((ele: any) => {
                    formData.append("image", ele);
                })

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `Bearer ${token}` } })
                if (resp.statusText == "OK") {
                    let filenames = resp.data.body.filenames;
                    payload.passport_proof_document = filenames;
                }
            }


            if (data && data.address_proof) {
                let formData = new FormData()

                formData.append("folder", imagefolder);
                data.address_proof.forEach((ele: any) => {
                    formData.append("image", ele);
                })

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { 'headers': { 'Authorization': `Bearer ${token}` } })
                if (resp.statusText == "OK") {
                    let filenames = resp.data.body.filenames;
                    payload.address_proof = filenames;
                }
            }


            payload.investor_proof_document = [...existingInvestorDocuments.map((ele: any) => ele.document), ...(payload.investor_proof_document || [])]


            payload.government_id_proof = [...existingGovernmentIdProofDocuments.map((ele: any) => ele.document), ...(payload.government_id_proof || [])]

            payload.passport_proof_document = [...existingPassportProofDocuments.map((ele: any) => ele.document), ...(payload.passport_proof_document || [])]

            payload.address_proof = [...existingAddressProofDocuments.map((ele: any) => ele.document), ...(payload.address_proof || [])]


            if (data._id) payload._id = data._id;

            let response: any = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/users/createUpdateUser`,
                payload, { 'headers': { 'Authorization': `Bearer ${token}` } })

            console.log("response----", response);


            if (response.status === 200) {
                toast.success(response.data.message)
                navigate("/users")
            } else {
                toast.error("Something went wrong.")
            }


        } catch (err) {
            console.log("err", err)
            toast.error(err.response.data.message)

            navigate("/user/addedit")
        }
    };

    //console.log("formik.values", formik.values)

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
        const day = String(today.getDate() - 1).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    return (
        <>
            {
                !isLoading ? (
                    <div className="main_layout">

                        <h2>
                            {addMode ? "Add" : "Edit"} user
                        </h2>
                        <div className="add_edit_box">
                            <form className='add-category' onSubmit={formik.handleSubmit}>
                                <div className="flex-fg">


                                    {/* <SelectedImagesWithPreview
                                        fieldName={"investor_proof_document"}
                                        label={"Investor Id Proof Documents"}
                                        formik={formik}
                                        setFieldImages={(e: any) => {
                                            console.log("e--", e)
                                            formik.setFieldValue("investor_proof_document", e ? Array.from(e) : e)
                                        }}
                                        fieldImages={formik?.values?.investor_proof_document}
                                        existingImages={existingInvestorDocuments}
                                        setExistingImages={setExistingInvestorDocuments}
                                        folder={imagefolder}
                                    />


                                    <SelectedImagesWithPreview
                                        fieldName={"government_id_proof"}
                                        label={"Government Id Proof Documents"}
                                        formik={formik}
                                        setFieldImages={(e: any) => {
                                            console.log("e--", e)
                                            formik.setFieldValue("government_id_proof", e ? Array.from(e) : e)
                                        }}
                                        fieldImages={formik?.values?.government_id_proof}
                                        existingImages={existingGovernmentIdProofDocuments}
                                        setExistingImages={setExistingGovernmentIdProofDocuments}
                                        folder={imagefolder}
                                    />



                                    <SelectedImagesWithPreview
                                        fieldName={"passport_proof_document"}
                                        label={"Passport Proof Documents"}
                                        formik={formik}
                                        setFieldImages={(e: any) => {
                                            console.log("e--", e)
                                            formik.setFieldValue("passport_proof_document", e ? Array.from(e) : e)
                                        }}
                                        fieldImages={formik?.values?.passport_proof_document}
                                        existingImages={existingPassportProofDocuments}
                                        setExistingImages={setExistingPassportProofDocuments}
                                        folder={imagefolder}
                                    />


                                    <SelectedImagesWithPreview
                                        fieldName={"address_proof"}
                                        label={"Address Proof Documents"}
                                        formik={formik}
                                        setFieldImages={(e: any) => {
                                            console.log("e--", e)
                                            formik.setFieldValue("address_proof", e ? Array.from(e) : e)
                                        }}
                                        fieldImages={formik?.values?.address_proof}
                                        existingImages={existingAddressProofDocuments}
                                        setExistingImages={setExistingAddressProofDocuments}
                                        folder={imagefolder}
                                    /> */}



                                    <div className='form-group'>
                                        <label>First name</label>
                                        <input
                                            name="first_name"
                                            type="text"
                                            placeholder="Enter First Name"
                                            className={
                                                'form-control' +
                                                (formik.errors.first_name && formik.touched.first_name
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.first_name}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.first_name && formik.touched.first_name
                                                ? formik.errors.first_name
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Last name</label>
                                        <input
                                            name="last_name"
                                            type="text"
                                            placeholder="Enter Last Name"
                                            className={
                                                'form-control' +
                                                (formik.errors.last_name && formik.touched.last_name
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.last_name}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.last_name && formik.touched.last_name
                                                ? formik.errors.last_name
                                                : null}
                                        </div>
                                    </div>


                                    {
                                        // addMode ? (
                                        <div className='form-group'>
                                            <label>Email</label>
                                            <input
                                                name="email"
                                                type="email"
                                                placeholder="Enter Email"
                                                className={
                                                    'form-control' +
                                                    (formik.errors.email && formik.touched.email
                                                        ? ' is-invalid'
                                                        : '')
                                                }
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                disabled={!addMode}
                                            />
                                            <div className="invalid-feedback">
                                                {formik.errors.email && formik.touched.email
                                                    ? formik.errors.email
                                                    : null}
                                            </div>
                                        </div>
                                        // ) : ""
                                    }
                                    <div className='form-group'>
                                        <label>Phone Number</label>

                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <input
                                                    name="country_code"
                                                    type="text"
                                                    placeholder="Enter Country Code"
                                                    className={
                                                        'form-control' +
                                                        (formik.errors.country_code && formik.touched.country_code
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    onChange={formik.handleChange}
                                                    value={formik.values.country_code}
                                                />
                                                <div className="invalid-feedback">
                                                    {formik.errors.country_code && formik.touched.country_code
                                                        ? formik.errors.country_code
                                                        : null}
                                                </div>
                                            </Grid>
                                            <Grid item xs={8}>

                                                <input
                                                    name="phone_number"
                                                    type="text"
                                                    placeholder="Enter Phone Number"
                                                    className={
                                                        'form-control' +
                                                        (formik.errors.phone_number && formik.touched.phone_number
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    onChange={formik.handleChange}
                                                    value={formik.values.phone_number}
                                                />
                                                <div className="invalid-feedback">
                                                    {formik.errors.phone_number && formik.touched.phone_number
                                                        ? formik.errors.phone_number
                                                        : null}
                                                </div>
                                            </Grid>
                                        </Grid>


                                    </div>
                                    <div className='form-group'>
                                        <label>User name</label>
                                        <input
                                            name="username"
                                            type="text"
                                            placeholder="Enter Name"
                                            className={
                                                'form-control' +
                                                (formik.errors.username && formik.touched.username
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.username && formik.touched.username
                                                ? formik.errors.username
                                                : null}
                                        </div>
                                    </div>


                                    {
                                        addMode ? (
                                            <div className='form-group'>
                                                <label>Password</label>
                                                <input
                                                    name="password"
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    className={
                                                        'form-control' +
                                                        (formik.errors.password && formik.touched.password
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                />
                                                <div className="invalid-feedback">
                                                    {formik.errors.password && formik.touched.password
                                                        ? typeof formik.errors.password == "object" ? [...Object.values(formik.errors.password)].join(", ") : formik.errors.password
                                                        : null}
                                                </div>
                                            </div>
                                        ) : <></>
                                    }

                                    <div className='form-group'>
                                        <label>Type Of Account</label>
                                        <select
                                            name="type_of_account"
                                            placeholder="Select Type Of Account"
                                            className={
                                                'form-control' +
                                                (formik.errors.type_of_account && formik.touched.type_of_account
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.type_of_account}
                                        >
                                            <option value={""}>
                                                Select Type Of Account
                                            </option>

                                            <option value={"individual"}>
                                                I am an individual
                                            </option>

                                            <option value={"professional"}>
                                                I am a professional
                                            </option>

                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.type_of_account && formik.touched.type_of_account
                                                ? formik.errors.type_of_account
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Nationality</label>

                                        <ReactFlagsSelect
                                            searchable
                                            searchPlaceholder="Search country"
                                            selected={formik.values.nationality}
                                            onSelect={(code) => formik.setFieldValue("nationality", code)}
                                        />

                                        {/* <input
                                            name="nationality"
                                            type="text"
                                            placeholder="Select nationality"
                                            className={
                                                'form-control' +
                                                (formik.errors.nationality && formik.touched.nationality
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.nationality}
                                        /> */}
                                        <div className="invalid-feedback">
                                            {formik.errors.nationality && formik.touched.nationality
                                                ? formik.errors.nationality
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Date Of Birth</label>
                                        <input
                                            name="dob"
                                            type="date"
                                            placeholder="Enter Date Of Birth"
                                            className={
                                                'form-control' +
                                                (formik.errors.dob && formik.touched.dob
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            max={getTodayDate()}
                                            onChange={formik.handleChange}
                                            value={formik.values.dob}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.dob && formik.touched.dob
                                                ? formik.errors.dob
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>City Of Birth</label>
                                        <input
                                            name="city_of_birth"
                                            type="text"
                                            placeholder="Enter City Of Birth"
                                            className={
                                                'form-control' +
                                                (formik.errors.city_of_birth && formik.touched.city_of_birth
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.city_of_birth}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.city_of_birth && formik.touched.city_of_birth
                                                ? formik.errors.city_of_birth
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Native Country</label>
                                        <ReactFlagsSelect
                                            searchable
                                            searchPlaceholder="Search country"
                                            selected={formik.values.native_country}
                                            onSelect={(code) => formik.setFieldValue("native_country", code)}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.native_country && formik.touched.native_country
                                                ? formik.errors.native_country
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Address</label>
                                        <input
                                            name="address"
                                            type="text"
                                            placeholder="Enter Address"
                                            className={
                                                'form-control' +
                                                (formik.errors.address && formik.touched.address
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.address && formik.touched.address
                                                ? formik.errors.address
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Postal Code</label>
                                        <input
                                            name="postal_code"
                                            type="text"
                                            placeholder="Enter Postal Code"
                                            className={
                                                'form-control' +
                                                (formik.errors.postal_code && formik.touched.postal_code
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.postal_code}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.postal_code && formik.touched.postal_code
                                                ? formik.errors.postal_code
                                                : null}
                                        </div>
                                    </div>



                                    <div className='form-group'>
                                        <label>City</label>
                                        <input
                                            name="city"
                                            type="text"
                                            placeholder="Enter City"
                                            className={
                                                'form-control' +
                                                (formik.errors.city && formik.touched.city
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.city}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.city && formik.touched.city
                                                ? formik.errors.city
                                                : null}
                                        </div>
                                    </div>




                                    <div className='form-group'>
                                        <label>Country</label>
                                        <ReactFlagsSelect
                                            searchable
                                            searchPlaceholder="Search country"
                                            selected={formik.values.country}
                                            onSelect={(code) => formik.setFieldValue("country", code)}
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.country && formik.touched.country
                                                ? formik.errors.country
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label htmlFor="investment-objectives">Investment Objectives</label>

                                        <Select
                                            name="investment_objectives"
                                            label=""
                                            // label="Select Investment Objectives"
                                            displayEmpty
                                            className={
                                                'form-control' +
                                                (formik.errors.investment_objectives && formik.touched.investment_objectives
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.investment_objectives}

                                            multiple={true}
                                            style={{
                                                width: "631.11px",
                                                // maxWidth: "631.11px",
                                                height: "42.84px",
                                                // maxHeight: "42.84px"
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}


                                        >


                                            <MenuItem disabled value="">
                                                <em>Select Investment Objectives</em>
                                            </MenuItem>

                                            <MenuItem value={"obtain_additional_income"}>
                                                Obtain additional income
                                            </MenuItem>

                                            <MenuItem value={"diversify_my_investment"}>
                                                Diversify my investment
                                            </MenuItem>

                                            <MenuItem value={"increase_the_value_of_my_capital"}>
                                                Increase the value of my capital
                                            </MenuItem>

                                            <MenuItem value={"improve_profitability_on_my_investments"}>
                                                Improve profitability on my investments
                                            </MenuItem>

                                        </Select>
                                        <div className="invalid-feedback">
                                            {formik.errors.investment_objectives && formik.touched.investment_objectives
                                                ? formik.errors.investment_objectives
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Investment Horizon</label>
                                        <select
                                            name="investment_horizon"
                                            placeholder="Select Investment Horizon"
                                            className={
                                                'form-control' +
                                                (formik.errors.investment_horizon && formik.touched.investment_horizon
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.investment_horizon}
                                        >
                                            <option value={""}>
                                                Select Investment Horizon
                                            </option>

                                            <option value={"short_term"}>
                                                Short Term (Less than 2 years)
                                            </option>

                                            <option value={"medium_term"}>
                                                Medium Term (2 to 5 years)
                                            </option>

                                            <option value={"long_term"}>
                                                Long Term (Over 5 years)
                                            </option>

                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.investment_horizon && formik.touched.investment_horizon
                                                ? formik.errors.investment_horizon
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Professional Status</label>
                                        <select
                                            name="professional_status"
                                            placeholder="Select Professional Status"
                                            className={
                                                'form-control' +
                                                (formik.errors.professional_status && formik.touched.professional_status
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.professional_status}
                                        >
                                            <option value={""}>
                                                Select Professional Status
                                            </option>

                                            <option value={"in_activity"}>
                                                In activity
                                            </option>

                                            <option value={"retired"}>
                                                Retired
                                            </option>

                                            <option value={"Student"}>
                                                Student
                                            </option>

                                            <option value={"without_professional_activity"}>
                                                Without professional activity
                                            </option>

                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.professional_status && formik.touched.professional_status
                                                ? formik.errors.professional_status
                                                : null}
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Income Currency</label>
                                        <select
                                            name="income_currency"
                                            placeholder="Enter Income Currency"
                                            className={
                                                'form-control' +
                                                (formik.errors.income_currency && formik.touched.income_currency
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.income_currency}
                                        >
                                            <option value={""}>
                                                Select Income Currency
                                            </option>

                                            <option value={"$"}>
                                                $
                                            </option>

                                            <option value={"€"}>
                                                €
                                            </option>
                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.income_currency && formik.touched.income_currency
                                                ? formik.errors.income_currency
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Annual Income Range</label>
                                        <select
                                            name="annual_income_range"
                                            placeholder="Select Annual Income Range"
                                            className={
                                                'form-control' +
                                                (formik.errors.annual_income_range && formik.touched.annual_income_range
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.annual_income_range}
                                        >
                                            <option value={""}>
                                                Select Annual Income Range
                                            </option>

                                            <option value={"below_20000"}>
                                                Below 20,000
                                            </option>

                                            <option value={"between_20000_and_50000"}>
                                                Between 20,000 and 50,000
                                            </option>

                                            <option value={"between_50000_and_75000"}>
                                                Between 50,000 and 75,000
                                            </option>

                                            <option value={"between_75000_and_100000"}>
                                                Between 75,000 and 100,000
                                            </option>

                                            <option value={"over_100000"}>
                                                Over 100,000
                                            </option>

                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.annual_income_range && formik.touched.annual_income_range
                                                ? formik.errors.annual_income_range
                                                : null}
                                        </div>
                                    </div>


                                    <div className='form-group'>
                                        <label>Total Wealth</label>
                                        <select
                                            name="estimated_total_wealth"
                                            placeholder="Select Total Wealth Range"
                                            className={
                                                'form-control' +
                                                (formik.errors.estimated_total_wealth && formik.touched.estimated_total_wealth
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.estimated_total_wealth}
                                        >
                                            <option value={""}>
                                                Select Estimated Total Wealth Range
                                            </option>

                                            <option value={"below_50000"}>
                                                Below 50,000
                                            </option>

                                            <option value={"between_50000_and_100000"}>
                                                Between 50,000 and 100,000
                                            </option>

                                            <option value={"between_100000_and_200000"}>
                                                Between 100,000 and 200,000
                                            </option>

                                            <option value={"between_200000_and_400000"}>
                                                Between 200,000 and 400,000
                                            </option>

                                            <option value={"over_400000"}>
                                                Over 400,000
                                            </option>

                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.estimated_total_wealth && formik.touched.estimated_total_wealth
                                                ? formik.errors.estimated_total_wealth
                                                : null}
                                        </div>
                                    </div>



                                </div>

                                <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="spinner" >

                        <HashLoader color="#1864ab" size={75} loading={true} />
                    </div>
                )
            }
        </>


    );
};

export default AddEditMain;
