
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { ReactComponent as ViewSvg } from '../../images/eye-svgrepo-com.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { getPaymentsThunk } from '../../Redux/Thunks/paymentThunk';
import { paymentSelector } from "../../Redux/Selectors/paymentSelector";
import moment from 'moment';
import AlertDialog from "../Dialog/Alert";

const List = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { payments, totalpayments }: any = useSelector(paymentSelector)
    const token = localStorage.getItem('currentToken');
    const limit = 10;

    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(totalpayments / limit);
        return pageCounter;
    }, [totalpayments, limit]);

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [elementId, setElementId] = useState("")

    useEffect(() => {
        getlist();
    }, [])

    const getlist = async () => {
        const resp: any = await dispatch(getPaymentsThunk({ page: 1, limit: limit, token: token }));
    }

    const handlePageClick = async (data: any) => {
        console.log(data)
        await dispatch(
            getPaymentsThunk({
                limit: limit,
                page: data.selected + 1,
                token: token,
            })
        );
        // getData(configObject)
    };

    return (
        <>
            <div className="main_layout">
                <h2>Payments List</h2>
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>TxnID </th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                totalpayments > 0 ?
                                    payments.map((item: any) => {
                                        return (
                                            <tr key={item?._id}>
                                                <td>{item?.user?.full_name}</td>
                                                <td>{item?.txnId}</td>
                                                <td>{item?.amount}</td>
                                                <td>{item?.type}</td>
                                                <td>{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>{moment(item?.updatedAt).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        )
                                    })
                                    : <tr><td colSpan={6} className="text-center">No record found</td></tr>
                            }
                        </tbody>
                    </table>
                    {
                        totalpayments > 0 && (
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                //    breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"pagination_prev"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"pagination_next"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )
                    }
                </div>
            </div >
        </>
    );
};

export default List;
