import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MantineProvider } from "@mantine/core";

import "./App.css";
import Home from "./components/Home/Home";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import Settings from "./components/Setting/Settings";
import Users from "./components/Users/Main";
import Dashboard from "./components/Dashboard/Main";
import Notifications from "./components/Notifications/Notification";

import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Information from "./components/ForgotPassword/Information";
import PageNotFound from "./components/ForgotPassword/PageNotFound";
import EditUser from "./components/Users/AddEdit";
import ViewUser from "./components/Users/View";

import Properties from "./components/Properties/Main";
import PropertiesAddEdit from "./components/Properties/AddEdit";
import PropertiesView from "./components/Properties/View";

import AuthenticatedAdminLayout from "./Layout/AuthenticatedAdminLayout";
import Categories from "./components/Categories/List";
import CreateCategory from "./components/Categories/Create";
import EditCategory from "./components/Categories/Edit";
import Subscribers from "./components/Subscribers/List";
import Payments from "./components/Payments/List";
import Investments from "./components/Investments/List";
import Faqs from "./components/Faqs/List";
import Tutorials from "./components/Tutorials/List";
import CreateFaq from "./components/Faqs/Create";
import EditFaq from "./components/Faqs/Edit";
import CreateTutorial from "./components/Tutorials/Create";
import EditTutorial from "./components/Tutorials/Edit";
import Blogs from "./components/Blogs/List";
import CreateBlog from "./components/Blogs/Create";
import EditBlog from "./components/Blogs/Edit";
import Payout from "./components/Payments/Payout";
import Message from "./components/Messages/List";

function App() {
  return (
    <MantineProvider theme={{}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>

          <Route path="/info" element={<Information />}></Route>


          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Dashboard /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/addedit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><EditUser /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/user/view"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><ViewUser /></AuthenticatedAdminLayout>

              </ProtectedRoute>
            }
          />


          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Users /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />


          <Route
            path="/properties"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Properties /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/property/addedit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><PropertiesAddEdit /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />


          <Route
            path="/property/view"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><PropertiesView /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings/:username"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Settings /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/categories"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Categories /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/categories/create"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><CreateCategory /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/categories/edit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><EditCategory /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/subscribers"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Subscribers /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/payments"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Payments /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/investments"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Investments /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/faqs"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Faqs /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/faqs/create"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><CreateFaq /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/faqs/edit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><EditFaq /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/tutorials"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Tutorials /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/tutorials/create"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><CreateTutorial /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/tutorials/edit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><EditTutorial /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/blogs"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Blogs /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/blogs/create"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><CreateBlog /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/blogs/edit"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><EditBlog /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/payouts"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Payout /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/messages"
            element={
              <ProtectedRoute>
                <AuthenticatedAdminLayout ><Message /></AuthenticatedAdminLayout>
              </ProtectedRoute>
            }
          />

        </Routes>

      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
