import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getPaymentsThunk, getPayoutsThunk } from "./Thunks/paymentThunk";


export interface PaymentState {
    totalpayments: any,
    payments: any;
    payment: Object;
    page: any;
    limit: any;
    loadingStatus: any;

    totalpayouts: any,
    payouts: any,
    payoutpage: any,
    payoutlimit: any,
}

const initialState: PaymentState = {
    totalpayments: 0,
    payments: [],
    payment: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",

    totalpayouts: 0,
    payouts: [],
    payoutpage: 1,
    payoutlimit: 10,
};


export const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.payments = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get categories
            .addCase(getPaymentsThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                // state.categories = [];
            })
            .addCase(getPaymentsThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.payments = action.payload.data.body.data;
                state.totalpayments = action.payload.data.body.count
            })
            .addCase(getPaymentsThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
            })
            .addCase(getPayoutsThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.payouts = action.payload.data.body.data;
                state.totalpayouts = action.payload.data.body.count
            })
    }
});

// Action creators are generated for each case reducer function
export const { getAll } =
    paymentSlice.actions;

export default paymentSlice.reducer;
