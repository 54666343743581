import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getInvestmentsThunk, getInvestmentStatsThunk } from "./Thunks/investmentThunk";


export interface CategoryState {
    totalInvestments: any,
    investments: any;
    investment: Object;
    page: any;
    limit: any;
    loadingStatus: any;
}

const initialState: CategoryState = {
    totalInvestments: 0,
    investments: [],
    investment: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
};


export const investmentSlice = createSlice({
    name: "investments",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.investments = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get categories
            .addCase(getInvestmentsThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                // state.categories = [];
            })
            .addCase(getInvestmentsThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.investments = action.payload.data.body.data;
                state.totalInvestments = action.payload.data.body.count

            })
            .addCase(getInvestmentsThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
            })
            .addCase(getInvestmentStatsThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.investments = action.payload.data.body.data;
                state.totalInvestments = action.payload.data.body.count

            })
    }
});

// Action creators are generated for each case reducer function
export const { getAll } = investmentSlice.actions;
export default investmentSlice.reducer;
