
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as CancelSvg } from '../../images/times.svg'
import { ReactComponent as ApproveSvg } from '../../images/check.svg'
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { getPayoutsThunk, updatePayoutThunk } from '../../Redux/Thunks/paymentThunk';
import { paymentSelector } from "../../Redux/Selectors/paymentSelector";
import moment from 'moment';
import swal from "sweetalert";

const Payout = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { payouts, totalpayouts }: any = useSelector(paymentSelector)
    const token = localStorage.getItem('currentToken');
    const limit = 10;

    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(totalpayouts / limit);
        return pageCounter;
    }, [totalpayouts, limit]);


    useEffect(() => {
        getlist();
    }, [])

    const getlist = async () => {
        const resp: any = await dispatch(getPayoutsThunk({ page: 1, limit: limit, token: token }));
    }

    const handlePageClick = async (data: any) => {
        console.log(data)
        await dispatch(
            getPayoutsThunk({
                limit: limit,
                page: data.selected + 1,
                token: token,
            })
        );
    };

    const handleUpdatePayout = async (id: any, status: any) => {
        try {

            let payload = {
                id: id,
                status: status,
                token: token
            }
            await swal({
                title: "Are you sure?",
                text: `You make payout as ${status}`,
                icon: "warning",
                dangerMode: status === 'approved' ? false : true,
            }).then(async (willChange) => {
                if (willChange) {

                    let response: any = await dispatch(updatePayoutThunk(payload));
                    if (response?.payload?.status === 200) {
                        //await getUsers(configObject);
                        await dispatch(getPayoutsThunk({ page: 1, limit: limit, token: token }));
                        swal({
                            title: "Done!",
                            text: `${status}`,
                            icon: "success",
                            timer: 2000,
                        });
                    } else {
                        toast.error("Something went wrong.")
                    }
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="main_layout">
                <h2>payout Requests</h2>
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Amount</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                totalpayouts > 0 ?
                                    payouts.map((item: any) => {
                                        return (
                                            <tr key={item?._id}>
                                                <td>{item?.user?.full_name}</td>
                                                <td>{item?.amount}</td>
                                                <td>{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>{moment(item?.updatedAt).format('YYYY-MM-DD')}</td>
                                                {
                                                    item?.status === 'pending' ?
                                                        <td style={{ cursor: "pointer" }}>
                                                            <ApproveSvg height={"20px"} style={{ cursor: "pointer" }} onClick={() => handleUpdatePayout(item?._id, 'approved')} />
                                                            <CancelSvg height={"15px"} style={{ cursor: "pointer" }} onClick={() => handleUpdatePayout(item?._id, 'rejected')} />
                                                        </td> :
                                                        <td className="text-capitalize">{item?.status}</td>
                                                }
                                            </tr>
                                        )
                                    })
                                    : <tr><td colSpan={6} className="text-center">No record found</td></tr>
                            }
                        </tbody>
                    </table>
                    {
                        totalpayouts > 0 && (
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                //    breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"pagination_prev"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"pagination_next"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        )
                    }

                </div>
            </div >
        </>
    );
};

export default Payout;
